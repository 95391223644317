import { deliveriesIntlIds } from './deliveries.ids';

export const deliveriesLangPtBr = {
  [deliveriesIntlIds.toast.actionDispatched.title]: 'Ação em andamento',
  [deliveriesIntlIds.toast.actionDispatched.description]:
    'Aguarde alguns instantes enquanto finalizamos esta ação',

  [deliveriesIntlIds.seo.pages.list.title]: 'Lista de Entregas',
  [deliveriesIntlIds.seo.pages.list.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [deliveriesIntlIds.seo.pages.details.title]: 'Entrega {delivery_id}',
  [deliveriesIntlIds.seo.pages.details.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [deliveriesIntlIds.list.hub.title]: `Entregas: {hubs_length, plural,
      =0 {Sem Hubs}
      one {{hub_alias}}
      other {<highlight>#</highlight> Hubs}}`,
  [deliveriesIntlIds.list.controlTower.title]: 'Entregas: Hubs',
  [deliveriesIntlIds.list.tab.inProgress]: 'Em Andamento ({total_count})',
  [deliveriesIntlIds.list.tab.inManualAnalysis]: 'Em Análise ({total_count})',

  [deliveriesIntlIds.deliveryStatus.delivered]: 'Entregue',
  [deliveriesIntlIds.deliveryStatus.canceled]: 'Cancelada',
  [deliveriesIntlIds.deliveryStatus.processing]: 'Processando',
  [deliveriesIntlIds.deliveryStatus.ready]: 'Pronta',
  [deliveriesIntlIds.deliveryStatus.inRoute]: 'Em Rota',
  [deliveriesIntlIds.deliveryStatus.arrival]: 'No Endereço',
  [deliveriesIntlIds.deliveryStatus.inManualAnalysis]: 'Em Análise',

  [deliveriesIntlIds.deliveryStatus.emphasis.processing]: '<b>Processando</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.ready]: '<b>Pronta</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.inRoute]: '<b>Em Rota</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.arrival]: '<b>No Endereço</b>',

  [deliveriesIntlIds.deliveryAssortmentType.core]: 'Daki Core',
  [deliveriesIntlIds.deliveryAssortmentType.plus]: 'SuperDaki',

  [deliveriesIntlIds.deliverySearch.type.orderId]: 'ID Pedido',
  [deliveriesIntlIds.deliverySearch.type.riderId]: 'ID Rider',

  [deliveriesIntlIds.deliverySearch.input.label]: 'Procure uma Entrega',
  [deliveriesIntlIds.deliverySearch.input.placeholder]: 'Buscar',
  [deliveriesIntlIds.deliverySearch.submitButton.label]: 'Buscar',

  [deliveriesIntlIds.deliveryTable.cellHeader.hub]: 'Hub',
  [deliveriesIntlIds.deliveryTable.cellHeader.orderId]: 'ID pedido',
  [deliveriesIntlIds.deliveryTable.cellHeader.orderedAt]: 'Realizado em',
  [deliveriesIntlIds.deliveryTable.cellHeader.deliveryEta]: 'ETA Entrega',
  [deliveriesIntlIds.deliveryTable.cellHeader.client]: 'Cliente',
  [deliveriesIntlIds.deliveryTable.cellHeader.address]: 'Endereço',
  [deliveriesIntlIds.deliveryTable.cellHeader.status]: 'Status',
  [deliveriesIntlIds.deliveryTable.cellHeader.riderAssignment]:
    'Atribuição de rider',

  [deliveriesIntlIds.details.title]: 'Entrega {delivery_id}',
  [deliveriesIntlIds.details.breadcrumb.deliveries]: 'Entregas',
  [deliveriesIntlIds.details.breadcrumb.deliveryDetails]: 'Detalhes da Entrega',
  [deliveriesIntlIds.details.hub]: 'Hub:',
  [deliveriesIntlIds.details.deliveryETA]: 'ETA Entrega:',
  [deliveriesIntlIds.details.deliveryStatus]: 'Status da Entrega:',

  [deliveriesIntlIds.details.changeStatus.button
    .label]: `Alterar para {delivery_status, select,
      pending {Pronto}
      pre_processed {Pronto}
      processed {Pronto}
      picking {Pronto}
      ready {Em Rota}
      on_the_way {No Endereço}
      arrived {Entregue}
      delivered {}
      canceled {}
      unknown {}
      other {}}`,

  [deliveriesIntlIds.details.confirmDeliveryReceipt.button.label]:
    'Confirmar recebimento',

  [deliveriesIntlIds.details.order.confirmedAt.label]: 'Pedido confirmado',
  [deliveriesIntlIds.details.order.id.label]: 'ID Pedido',
  [deliveriesIntlIds.details.order.link.label]: 'Abrir o pedido',

  [deliveriesIntlIds.details.history.box.delivery.label]:
    'Histórico de Entrega',
  [deliveriesIntlIds.details.history.box.delivery.arrivedAt.label]:
    'Rider chegou no endereço',
  [deliveriesIntlIds.details.history.box.delivery.deliveredAt.label]:
    'Rider entregou o pedido',
  [deliveriesIntlIds.details.history.box.delivery.canceledAt.label]:
    'Pedido cancelado',

  [deliveriesIntlIds.details.history.box.return.label]:
    'Histórico de Devolução',
  [deliveriesIntlIds.details.history.box.return.tripPointCanceledAt.label]:
    'Rider não conseguiu entregar',
  [deliveriesIntlIds.details.history.box.return.tripPointCancelationReason
    .label]: 'Motivo',
  [deliveriesIntlIds.details.history.box.return.returnedAt.label]:
    'Pedido devolvido',
  [deliveriesIntlIds.details.history.box.return.returnConfirmedBy.label]:
    'Confirmado por',
  [deliveriesIntlIds.details.history.box.return.canceledAt.label]:
    'Pedido cancelado',
  [deliveriesIntlIds.details.history.box.return.tripPointCancelationReason
    .value]: `{cancelation_reason, select,
    customer_not_found {Não encontrei o cliente}
    refused_by_customer {Cliente recusou a entrega}
    wrong_address {O endereço está errado}
    invalid_delivery_code {Código informado está inválido}
    delivery_code_not_provided {Cliente não informou o código}
    other {}
  }`,

  [deliveriesIntlIds.details.customer.address.label]: 'Endereço',
  [deliveriesIntlIds.details.customer.name.label]: 'Nome da(o) cliente',
  [deliveriesIntlIds.details.customer.notes.label]: 'Observações da(o) cliente',
  [deliveriesIntlIds.details.trip.id.label]: 'VIAGEM: {trip_id}',
  [deliveriesIntlIds.details.trip.mode.label]:
    '{trip_mode, select, manual {Manual} automatic {Automática} other {}}',
  [deliveriesIntlIds.details.trip.assignedRiders.label]:
    'Riders Atribuídos ({riders_count})',
  [deliveriesIntlIds.details.trip.assignRider.label]: 'Atribuir Rider',
  [deliveriesIntlIds.details.trip.requestExternalDispatch.label]:
    'Solicitar Uber',
  [deliveriesIntlIds.details.trip.suggestedModality.label]: 'Modal indicado:',
  [deliveriesIntlIds.details.trip.suggestedModality.tag.bicycle.label]:
    'Bicicleta',
  [deliveriesIntlIds.details.trip.suggestedModality.tag.motorcycle.label]:
    'Moto',
  [deliveriesIntlIds.details.rider.profile.label]: 'Ver perfil',
  [deliveriesIntlIds.details.rider.changeRider.label]: 'Mudar Rider',
  [deliveriesIntlIds.details.rider.assignmentStatus.assigned.label]:
    'Atribuído',
  [deliveriesIntlIds.details.rider.assignmentStatus.offering.label]:
    'Ofertando',
  [deliveriesIntlIds.details.rider.assignmentStatus.fail.label]:
    'Falha ao enviar oferta',

  [deliveriesIntlIds.riderAssignment.mode
    .manualOrAutomatic]: `{riders_count, plural,
      =0 {{trip_mode, select, manual {Manual} automatic {Automática} other {}}}
      one {{trip_mode, select, manual {Manual} automatic {Automática} other {}}}
      other {# {trip_mode, select, manual {Manual} automatic {Automática} other {}}}}`,
  [deliveriesIntlIds.riderAssignment.mode.manualAndAutomatic]:
    '{manual_trips_count} Manual, {automatic_trips_count} Automática',
  [deliveriesIntlIds.riderAssignment.riderAssigned.modal.label]: 'Modal',
  [deliveriesIntlIds.riderAssignment.riderAssigned.modal.logisticsOperatorType]:
    'Operador Logístico (OL)',
  [deliveriesIntlIds.riderAssignment.status.noRiderAssigned]:
    'Nenhum Rider atribuído',
  [deliveriesIntlIds.riderAssignment.status.findingRider]: 'Ofertando',
  [deliveriesIntlIds.riderAssignment.status.riderAssigned]: '{rider_name}',
  [deliveriesIntlIds.riderAssignment.status.ridersAssigned]:
    '{riders_assigned_count}/{riders_count} Riders atribuídos',

  [deliveriesIntlIds.changeDeliveryStatusModal.title]:
    'Alterar status de Entrega?',
  [deliveriesIntlIds.changeDeliveryStatusModal.description]:
    '<b>Esta ação impacta a operação.</b> Aconselhamos que você revise antes de salvar',
  [deliveriesIntlIds.changeDeliveryStatusModal.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.changeDeliveryStatusModal.buttons.saveAndContinue.label]:
    'Salvar e continuar',
  [deliveriesIntlIds.changeDeliveryStatusModal.toast.onSuccess
    .label]: `O status da Entrega mudou para {delivery_status, select,
        pending {Pronta}
        pre_processed {Pronto}
        ready {Em Rota}
        on_the_way {No Endereço}
        arrived {Entregue}
        delivered {}
        canceled {Cancelado}
        unknown {}
        other {}}`,
  [deliveriesIntlIds.changeDeliveryStatusModal.toast.onError.label]:
    'Erro ao alterar o status da Entrega',

  [deliveriesIntlIds.confirmDeliveryReceiptModal.title]:
    'Alguém da loja recebeu o pedido?',
  [deliveriesIntlIds.confirmDeliveryReceiptModal
    .description]: `Se confirmar esta ação, ela não poderá ser desfeita.\n\n
    É obrigatório aguardar até que alguém da loja receba e confira os itens do pedido {action_author, select,
      external_dispatch {do entregador da Uber}
      rider {da pessoa Rider}
      other {}} antes de continuar.\n\n
    Assim que confirmar o recebimento, o pedido será cancelado.`,
  [deliveriesIntlIds.confirmDeliveryReceiptModal.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.buttons.saveAndContinue.label]:
    'Confirmar recebimento',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess
    .title]: `A loja recebeu o pedido {action_author, select,
      external_dispatch {pelo entregador Uber}
      rider {pela pessoa Rider}
      other {}}`,
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess.description]:
    'Agora basta aguardar o pedido ser cancelado de forma automática',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onError.title]:
    'Erro ao confirmar recebimento',

  [deliveriesIntlIds.changeOrAssignTripRiderModal.title]:
    'Novo Rider: Entrega {delivery_id}',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.placeholder]:
    'Selecionar Rider',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.options.noRiders]:
    'Sem Rider',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.buttons.sendOffer.label]:
    'Enviar oferta',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.riderModality
    .label]: `{modality, select,
        bicycle {Bicicleta}
        motorcycle {Moto}
        other {}
      }`,

  [deliveriesIntlIds.requestExternalDeliveryDispatch.title]:
    'Precisa de um Uber para essa entrega?',
  [deliveriesIntlIds.requestExternalDeliveryDispatch
    .description]: `Se solicitar um entregador da Uber para realizar esta entrega, ela só será ofertada para as pessoas Riders de forma manual.
    Acesse o BTLR para verificar a quantidade de itens do pedido para selecionar o peso do pedido e o motivo para solicitar.`,
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.small]: '1-2 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.medium]:
    '2-5 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.large]:
    '5-10 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.xlarge]:
    '10-20 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.selectReason]:
    'Selecione o motivo',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons
    .totalAbsenceOfRiders]: 'Ausência total de Riders',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.largeOrder]:
    'Pedido GG: Riders se negam à levar o pedido',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.rain]:
    'Chuva: Riders se negam à levar o pedido',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.strike]:
    'Paralisação: Riders se negam à levar o pedido',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.distance]:
    'Distância: Riders se negam à levar o pedido',

  [deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.request.label]:
    'Solicitar Uber',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.cancelExternalDispatch.title]:
    'Precisa cancelar a entrega pelo Uber?',
  [deliveriesIntlIds.cancelExternalDispatch
    .description]: `Saiba que se a entrega pelo Uber estiver em andamento, você precisará <b>aguardar até que a loja confirme que o entregador da Uber devolveu o pedido antes de cancelar o pedido</b>\n\nSe o pedido não for cancelado, a sua entrega só será ofertada para as pessoas Riders de forma manual.\n\n<b>Selecione quem solicitou e o motivo para cancelar</b>`,
  [deliveriesIntlIds.cancelExternalDispatch.agent.client]: 'Cliente',
  [deliveriesIntlIds.cancelExternalDispatch.agent.internal]: 'Loja ou CX',
  [deliveriesIntlIds.cancelExternalDispatch.agent.rider]: 'Entregador Uber',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.selectReason]:
    'Selecione o motivo',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.cancelation]:
    'Desistência: Cliente cancelou o pedido',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.wrong_info]:
    'Compra ou endereço errado: Cliente cancelou o pedido',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_receive]:
    'Suspeita de fraude: Cliente se recusou a receber',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_order]:
    'Suspeita de fraude: Cliente diz que não realizou a compra',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.no_receive]:
    'Cliente se recusou a receber',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.fraud]:
    'Suspeita de Fraude: Entregador não retirou o pedido',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.unexpected]:
    'Imprevistos: Entregador não pode continuar a entrega',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.risk_area]:
    'Área de risco: Entregador se recusou a continuar',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.client_not_found]:
    'Entregador não encontrou o cliente',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.address_not_found]:
    'Entregador não encontrou o endereço de entrega',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal.available_rider]:
    'Loja: Existem pessoas Riders disponíveis para a entrega',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal
    .wait_time_too_long]:
    'CX: A solicitação está demorando mais tempo que o normal',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal.wrong_request]:
    'CX: A solicitação não era para ter sido realizada',
  [deliveriesIntlIds.cancelExternalDispatch.buttons.request_cancel.label]:
    'Cancelar Uber',
  [deliveriesIntlIds.cancelExternalDispatch.buttons.cancel.label]: 'Cancelar',
  [deliveriesIntlIds.externalDispatch.details.uberId]:
    'ID Pedido Uber: {uberId}',
  [deliveriesIntlIds.externalDispatch.details.model]: 'Modelo:',
  [deliveriesIntlIds.externalDispatch.details.modality]: 'Modal:',
  [deliveriesIntlIds.externalDispatch.details.trip]: 'VIAGEM:',
  [deliveriesIntlIds.externalDispatch.details.manual]: 'Manual:',
  [deliveriesIntlIds.externalDispatch.details.openUber]: 'Abrir na Uber',
  [deliveriesIntlIds.externalDispatch.details.cancelUber]: 'Cancelar Uber',
  [deliveriesIntlIds.externalDispatch.details.status]: `{status, select,
    pending {Ofertando para entregadores Uber}
    returning {Uber retornando com pedido}
    returned {Uber retornou à loja}
    other {Uber atribuído}
  }`,

  [deliveriesIntlIds.externalDispatch.toasts.failed.title]:
    'Erro ao solicitar Uber Direct',
  [deliveriesIntlIds.externalDispatch.toasts.pending.title]:
    'Entregador Uber solicitado',
  [deliveriesIntlIds.externalDispatch.toasts.riderAssigned.title]:
    'Entregador Uber atribuído',
  [deliveriesIntlIds.externalDispatch.toasts.onTheWay.title]:
    'Entregador Uber começou a entrega',
  [deliveriesIntlIds.externalDispatch.toasts.returning.title]:
    'Entregador Uber está retornando ao hub',
  [deliveriesIntlIds.externalDispatch.toasts.returned.title]:
    'Uber Direct cancelado',
  [deliveriesIntlIds.externalDispatch.toasts.canceled.title]:
    'Uber Direct cancelado',
  [deliveriesIntlIds.externalDispatch.toasts.failed.description]:
    'Algo deu errado. Tente novamente',
  [deliveriesIntlIds.externalDispatch.toasts.pending.description]:
    'Aguarde até que um seja atribuído',
  [deliveriesIntlIds.externalDispatch.toasts.riderAssigned.description]:
    'Acompanhe o trajeto pelo link de rastreio',
  [deliveriesIntlIds.externalDispatch.toasts.onTheWay.description]:
    'Acompanhe o trajeto pelo link de rastreio',
  [deliveriesIntlIds.externalDispatch.toasts.returning.description]:
    'O entregador não conseguiu entregar',
  [deliveriesIntlIds.externalDispatch.toasts.returned.description]:
    'Aguarde até o entregador Uber devolver o pedido',
  [deliveriesIntlIds.externalDispatch.toasts.canceled.description]:
    'Você precisa solicitar um novo entregador da Uber novamente',

  [deliveriesIntlIds.noDeliveriesFound
    .label]: `Não há Entregas em {delivery_status, select,
          in_manual_analysis {Em Análise}
          delivered {Entregue}
          canceled {Cancelada}
          unknown {}
          other {Andamento}
        }`,
  [deliveriesIntlIds.noDeliveriesFound.searchEmptyState.orderId
    .label]: `Ops! Não encontramos nenhum <highlight>ID de Pedido</highlight> em {delivery_status, select,
              in_manual_analysis {Em Análise}
              delivered {Entregue}
              canceled {Cancelada}
              unknown {}
              other {Andamento}} com <highlight>{search_value}</highlight>`,
  [deliveriesIntlIds.noDeliveriesFound.searchEmptyState.riderId
    .label]: `Ops! Não encontramos nenhum <highlight>ID de Rider</highlight> nas Entregas em {delivery_status, select,
            in_manual_analysis {Em Análise}
            delivered {Entregue}
            canceled {Cancelada}
            unknown {}
            other {Andamento}} com <highlight>{search_value}</highlight>`,
};
