import { deliveriesIntlIds } from './deliveries.ids';

export const deliveriesLangEnUs = {
  [deliveriesIntlIds.toast.actionDispatched.title]: 'Action in progress',
  [deliveriesIntlIds.toast.actionDispatched.description]:
    'Please wait a few moments while we complete this action',

  [deliveriesIntlIds.seo.pages.list.title]: 'Deliveries list',
  [deliveriesIntlIds.seo.pages.list.description]:
    'Web application for rider management and last mile deliveries',

  [deliveriesIntlIds.seo.pages.details.title]: 'Delivery {delivery_id}',
  [deliveriesIntlIds.seo.pages.details.description]:
    'Web application for rider management and last mile deliveries',

  [deliveriesIntlIds.list.hub.title]: `Deliveries: {hubs_length, plural,
    =0 {No Hubs}
    one {{hub_alias}}
    other {<highlight>#</highlight> Hubs}}`,
  [deliveriesIntlIds.list.controlTower.title]: 'Deliveries: Hubs',

  [deliveriesIntlIds.list.tab.inProgress]: 'In Progress ({total_count})',
  [deliveriesIntlIds.list.tab.inManualAnalysis]:
    'Under Analysis ({total_count})',

  [deliveriesIntlIds.deliveryStatus.delivered]: 'Delivered',
  [deliveriesIntlIds.deliveryStatus.canceled]: 'Canceled',
  [deliveriesIntlIds.deliveryStatus.processing]: 'Processing',
  [deliveriesIntlIds.deliveryStatus.ready]: 'Ready',
  [deliveriesIntlIds.deliveryStatus.inRoute]: 'In Route',
  [deliveriesIntlIds.deliveryStatus.arrival]: 'Arrival',
  [deliveriesIntlIds.deliveryStatus.inManualAnalysis]: 'Under Analysis',

  [deliveriesIntlIds.deliveryStatus.emphasis.processing]: '<b>Processing</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.ready]: '<b>Ready</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.inRoute]: '<b>In Route</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.arrival]: '<b>Arrival</b>',

  [deliveriesIntlIds.deliveryAssortmentType.core]: 'Daki Core',
  [deliveriesIntlIds.deliveryAssortmentType.plus]: 'SuperDaki',

  [deliveriesIntlIds.deliverySearch.type.orderId]: 'Order ID',
  [deliveriesIntlIds.deliverySearch.type.riderId]: 'Rider ID',

  [deliveriesIntlIds.deliverySearch.type.orderId]: 'Order ID',
  [deliveriesIntlIds.deliverySearch.type.riderId]: 'Rider ID',

  [deliveriesIntlIds.deliverySearch.input.label]: 'Search the deliveries',
  [deliveriesIntlIds.deliverySearch.input.placeholder]: 'Search',
  [deliveriesIntlIds.deliverySearch.submitButton.label]: 'Ok',

  [deliveriesIntlIds.deliveryTable.cellHeader.hub]: 'Hub',
  [deliveriesIntlIds.deliveryTable.cellHeader.orderId]: 'Order ID',
  [deliveriesIntlIds.deliveryTable.cellHeader.orderedAt]: 'Ordered At',
  [deliveriesIntlIds.deliveryTable.cellHeader.deliveryEta]: 'Delivery ETA',
  [deliveriesIntlIds.deliveryTable.cellHeader.client]: 'Client',
  [deliveriesIntlIds.deliveryTable.cellHeader.address]: 'Address',
  [deliveriesIntlIds.deliveryTable.cellHeader.status]: 'Status',
  [deliveriesIntlIds.deliveryTable.cellHeader.riderAssignment]:
    'Rider Assignment',

  [deliveriesIntlIds.details.title]: 'Delivery {delivery_id}',
  [deliveriesIntlIds.details.breadcrumb.deliveries]: 'Deliveries',
  [deliveriesIntlIds.details.breadcrumb.deliveryDetails]: 'Delivery details',
  [deliveriesIntlIds.details.hub]: 'Hub:',
  [deliveriesIntlIds.details.deliveryETA]: 'Delivery ETA:',
  [deliveriesIntlIds.details.deliveryStatus]: 'Delivery status:',

  [deliveriesIntlIds.details.changeStatus.button
    .label]: `Change to {delivery_status, select,
      pending {Ready}
      pre_processed {Ready}
      processed {Ready}
      picking {Ready}
      ready {In Route}
      on_the_way {Arrival}
      arrived {Delivered}
      delivered {}
      canceled {}
      unknown {}
      other {}}`,

  [deliveriesIntlIds.details.confirmDeliveryReceipt.button.label]:
    'Confirm receipt',

  [deliveriesIntlIds.details.order.confirmedAt.label]: 'Order confirmed',
  [deliveriesIntlIds.details.order.id.label]: 'Order ID',
  [deliveriesIntlIds.details.order.link.label]: 'Open order',

  [deliveriesIntlIds.details.history.box.delivery.label]: 'Delivery History',
  [deliveriesIntlIds.details.history.box.delivery.arrivedAt.label]:
    'Rider arrived at the address',
  [deliveriesIntlIds.details.history.box.delivery.deliveredAt.label]:
    'Rider delivered the order',
  [deliveriesIntlIds.details.history.box.delivery.canceledAt.label]:
    'Order canceled',

  [deliveriesIntlIds.details.history.box.return.label]: 'Return History',
  [deliveriesIntlIds.details.history.box.return.tripPointCanceledAt.label]:
    "Rider couldn't deliver",
  [deliveriesIntlIds.details.history.box.return.tripPointCancelationReason
    .label]: 'Reason',
  [deliveriesIntlIds.details.history.box.return.returnedAt.label]:
    'Order returned',
  [deliveriesIntlIds.details.history.box.return.returnConfirmedBy.label]:
    'Confirmed by',
  [deliveriesIntlIds.details.history.box.return.canceledAt.label]:
    'Order canceled',
  [deliveriesIntlIds.details.history.box.return.tripPointCancelationReason
    .value]: `{cancelation_reason, select,
    customer_not_found {I did not find the customer}
    refused_by_customer {Customer refused delivery}
    wrong_address {Address is wrong}
    invalid_delivery_code {The provided code is invalid}
    delivery_code_not_provided {Customer did not provide the code}
    other {}
  }`,

  [deliveriesIntlIds.details.customer.address.label]: 'Address',
  [deliveriesIntlIds.details.customer.name.label]: "Client's Name",
  [deliveriesIntlIds.details.customer.notes.label]: "Client's Notes",
  [deliveriesIntlIds.details.trip.id.label]: 'TRIP: {trip_id}',
  [deliveriesIntlIds.details.trip.mode.label]:
    '{trip_mode, select, manual {Manual} automatic {Automatic} other {}}',
  [deliveriesIntlIds.details.trip.assignedRiders.label]:
    'Assigned Riders ({riders_count})',
  [deliveriesIntlIds.details.trip.assignRider.label]: 'Assign rider',
  [deliveriesIntlIds.details.trip.requestExternalDispatch.label]:
    'Request Uber',
  [deliveriesIntlIds.details.trip.suggestedModality.label]: 'Indicated modal:',
  [deliveriesIntlIds.details.trip.suggestedModality.tag.bicycle.label]:
    'Bicycle',
  [deliveriesIntlIds.details.trip.suggestedModality.tag.motorcycle.label]:
    'Motorcycle',
  [deliveriesIntlIds.details.rider.profile.label]: 'See profile',
  [deliveriesIntlIds.details.rider.changeRider.label]: 'Change rider',
  [deliveriesIntlIds.details.rider.assignmentStatus.assigned.label]: 'Assigned',
  [deliveriesIntlIds.details.rider.assignmentStatus.offering.label]: 'Offering',
  [deliveriesIntlIds.details.rider.assignmentStatus.fail.label]:
    'Fail to send offer',

  [deliveriesIntlIds.riderAssignment.mode
    .manualOrAutomatic]: `{riders_count, plural,
      =0 {{trip_mode, select, manual {Manual} automatic {Automatic} other {}}}
      one {{trip_mode, select, manual {Manual} automatic {Automatic} other {}}}
      other {# {trip_mode, select, manual {Manual} automatic {Automatic} other {}}}}`,
  [deliveriesIntlIds.riderAssignment.mode.manualAndAutomatic]:
    '{manual_trips_count} Manual, {automatic_trips_count} Automatic',
  [deliveriesIntlIds.riderAssignment.riderAssigned.modal.label]: 'Modal',
  [deliveriesIntlIds.riderAssignment.riderAssigned.modal.logisticsOperatorType]:
    'Logistics Operator (LO)',
  [deliveriesIntlIds.riderAssignment.status.noRiderAssigned]:
    'No Rider assigned',
  [deliveriesIntlIds.riderAssignment.status.findingRider]: 'Offering',
  [deliveriesIntlIds.riderAssignment.status.riderAssigned]: '{rider_name}',
  [deliveriesIntlIds.riderAssignment.status.ridersAssigned]:
    '{riders_assigned_count}/{riders_count} Assigned Riders',

  [deliveriesIntlIds.changeDeliveryStatusModal.title]:
    'Change delivery status?',
  [deliveriesIntlIds.changeDeliveryStatusModal.description]:
    '<b>This action impacts the operation.</b> We advise you to revise before saving.',
  [deliveriesIntlIds.changeDeliveryStatusModal.buttons.cancel.label]: 'Cancel',
  [deliveriesIntlIds.changeDeliveryStatusModal.buttons.saveAndContinue.label]:
    'Save and continue',
  [deliveriesIntlIds.changeDeliveryStatusModal.toast.onSuccess
    .label]: `Delivery status is now {delivery_status, select,
    pending {Ready}
    pre_processed {Ready}
    processed {Ready}
    picking {Ready}
    ready {In Route}
    on_the_way {Arrival}
    arrived {Delivered}
    delivered {}
    canceled {Canceled}
    unknown {}
    other {}}`,
  [deliveriesIntlIds.changeDeliveryStatusModal.toast.onError.label]:
    'Fail to change delivery status',

  [deliveriesIntlIds.confirmDeliveryReceiptModal.title]:
    'Did anyone from the store receive the order?',
  [deliveriesIntlIds.confirmDeliveryReceiptModal
    .description]: `If you confirm this action, it cannot be undone.\n\n
    It is mandatory to wait until someone from the store receives and checks the items in the {action_author, select,
      external_dispatch {Uber driver's}
      rider {Rider's}
      other {}} order before continuing.\n\n
    Once you confirm receipt, the order will be automatically canceled.`,
  [deliveriesIntlIds.confirmDeliveryReceiptModal.buttons.cancel.label]:
    'Cancel',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.buttons.saveAndContinue.label]:
    'Confirm receipt',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess
    .title]: `Hub received the order via {action_author, select,
      external_dispatch {Uber driver}
      rider {Rider}
      other {}}`,
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess.description]:
    'Now just wait for the order to be canceled automatically',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onError.title]:
    'Fail to confirm receipt',

  [deliveriesIntlIds.changeOrAssignTripRiderModal.title]:
    'New rider: Delivery {delivery_id}',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.placeholder]:
    'Select Rider',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.options.noRiders]:
    'No Rider',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.buttons.cancel.label]:
    'Cancel',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.buttons.sendOffer.label]:
    'Send offer',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.riderModality
    .label]: `{modality, select,
      bicycle {Bicycle}
      motorcycle {Motorcycle}
      other {}
    }`,

  [deliveriesIntlIds.requestExternalDeliveryDispatch.title]:
    'Do you need an Uber for this delivery?',
  [deliveriesIntlIds.requestExternalDeliveryDispatch
    .description]: `If you request an Uber delivery person to carry out this delivery, it will only be offered to our Riders manually.
    Access BTLR to check the quantity of items in the order to select the order weight and reason for the request.`,
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.small]: '1-2 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.medium]:
    '2-5 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.large]:
    '5-10 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.xlarge]:
    '10-20 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.selectReason]:
    'Select the reason',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons
    .totalAbsenceOfRiders]: 'Total absence of Riders',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.largeOrder]:
    'Large order: Riders refuse to accept the request',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.rain]:
    'Rain: Riders refuse to accept the request',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.strike]:
    'Strike: Riders refuse to accept the request',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.distance]:
    'Distance: Riders refuse to accept the request',

  [deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.request.label]:
    'Request Uber',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.cancel.label]:
    'Cancel',
  [deliveriesIntlIds.cancelExternalDispatch.title]:
    'Do you need to cancel the delivery through Uber?',
  [deliveriesIntlIds.cancelExternalDispatch
    .description]: `Saiba que se a entrega pelo Uber estiver em andamento, você precisará <b>aguardar até que a loja confirme que o entregador da Uber devolveu o pedido antes de cancelar o pedido</b>\n\nSe o pedido não for cancelado, a sua entrega só será ofertada para as pessoas Riders de forma manual.\n\n<b>Selecione quem solicitou e o motivo para cancelar</b>`,
  [deliveriesIntlIds.cancelExternalDispatch.agent.client]: 'Client',
  [deliveriesIntlIds.cancelExternalDispatch.agent.internal]: 'Store or CX',
  [deliveriesIntlIds.cancelExternalDispatch.agent.rider]: 'Uber Driver',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.selectReason]:
    'Select the reason',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.cancelation]:
    'Cancellation: Customer canceled the order',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.wrong_info]:
    'Wrong purchase or address: Customer canceled the order',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_receive]:
    'Suspected fraud: Customer refused to receive',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_order]:
    'Suspected fraud: Customer says he did not make the purchase',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.no_receive]:
    'Customer refused to receive',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.fraud]:
    'Suspected Fraud: Driver did not pick up the order',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.unexpected]:
    'Unforeseen events: Driver cannot continue delivery',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.risk_area]:
    'Risk area: Driver refused to continue',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.client_not_found]:
    'Driver could not find the customer',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.address_not_found]:
    'Driver could not find the delivery address',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal.available_rider]:
    'Store: There are Riders available for delivery',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal
    .wait_time_too_long]: 'CX: The request is taking longer than usual',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal.wrong_request]:
    'CX: The request was not supposed to be made',
  [deliveriesIntlIds.cancelExternalDispatch.buttons.request_cancel.label]:
    'Cancel Uber',
  [deliveriesIntlIds.cancelExternalDispatch.buttons.cancel.label]: 'Cancel',
  [deliveriesIntlIds.externalDispatch.details.uberId]:
    'Uber Order ID: {uberId}',
  [deliveriesIntlIds.externalDispatch.details.model]: 'Model:',
  [deliveriesIntlIds.externalDispatch.details.modality]: 'Modality:',
  [deliveriesIntlIds.externalDispatch.details.trip]: 'TRIP:',
  [deliveriesIntlIds.externalDispatch.details.manual]: 'Manual:',
  [deliveriesIntlIds.externalDispatch.details.openUber]: 'Open on Uber',
  [deliveriesIntlIds.externalDispatch.details.cancelUber]: 'Cancel Uber',
  [deliveriesIntlIds.externalDispatch.details.status]: `{status, select,
    pending {Offering to Uber drivers}
    returning {Uber returning with order}
    returned {Uber returned to the hub}
    other {Uber assigned}
  }`,

  [deliveriesIntlIds.externalDispatch.toasts.failed.title]:
    'Error on requesting Uber Direct',
  [deliveriesIntlIds.externalDispatch.toasts.pending.title]:
    'Uber driver requested',
  [deliveriesIntlIds.externalDispatch.toasts.riderAssigned.title]:
    'Uber driver assigned',
  [deliveriesIntlIds.externalDispatch.toasts.onTheWay.title]:
    'Uber driver started delivery',
  [deliveriesIntlIds.externalDispatch.toasts.returning.title]:
    'Uber driver is returning to the hub',
  [deliveriesIntlIds.externalDispatch.toasts.returned.title]:
    'Uber Direct canceled',
  [deliveriesIntlIds.externalDispatch.toasts.canceled.title]:
    'Uber Direct canceled',
  [deliveriesIntlIds.externalDispatch.toasts.failed.description]:
    'Something went wrong. Try again',
  [deliveriesIntlIds.externalDispatch.toasts.pending.description]:
    'Wait for one to be assigned',
  [deliveriesIntlIds.externalDispatch.toasts.riderAssigned.description]:
    'Follow the route using the tracking link',
  [deliveriesIntlIds.externalDispatch.toasts.onTheWay.description]:
    'Follow the route using the tracking link',
  [deliveriesIntlIds.externalDispatch.toasts.returning.description]:
    'Driver was unable to deliver',
  [deliveriesIntlIds.externalDispatch.toasts.returned.description]:
    'Wait for the driver to return the order',
  [deliveriesIntlIds.externalDispatch.toasts.canceled.description]:
    'You need to request a new Uber driver again',

  [deliveriesIntlIds.noDeliveriesFound
    .label]: `There are no Deliveries in {delivery_status, select,
      in_manual_analysis {Under Analysis}
      delivered {Delivered}
      canceled {Canceled}
      other {Progress}
    }`,
  [deliveriesIntlIds.noDeliveriesFound.searchEmptyState.orderId
    .label]: `Oops! We couldn't find any <highlight>Order ID</highlight> in {delivery_status, select,
        in_manual_analysis {Under Analysis}
        delivered {Delivered}
        canceled {Canceled}
        other {Progress}} with <highlight>{search_value}</highlight>`,
  [deliveriesIntlIds.noDeliveriesFound.searchEmptyState.riderId
    .label]: `Oops! We couldn't find any <highlight>Rider ID</highlight> on Deliveries in {delivery_status, select,
      in_manual_analysis {Under Analysis}
      delivered {Delivered}
      canceled {Canceled}
      other {Progress}} with <highlight>{search_value}</highlight>`,
};
